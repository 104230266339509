/*
 * @Description: 角色api
 * @Author: 琢磨先生
 * @Date: 2022-05-09 16:36:26
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-09 16:37:42
 */
import http from "./axios_init";

export default {
  /**
   * 获取所有的角色
   * @returns
   */
  get_roles() {
    return http.get("/admin/v1/role");
  },
};

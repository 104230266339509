<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-09 10:53:36
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-05 15:01:57
-->
<template>
  <el-card shadow="never" class="box">
    <el-form ref="query" :model="query" :inline="true">
      <el-form-item label="姓名">
        <el-input v-model="query.q" placeholder="请输入姓名、账号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div class="operator">
      <el-button type="primary" size="small" @click="onEdit" round icon="plus"
        >新增</el-button
      >
    </div>
  </el-card>
  <el-card class="box data">
    <el-table
      v-loading="loading" 
      :data="tableData.data"
      @sort-change="onSort"  border
    >
      <!-- <el-table-column prop="id" label="编号" width="80px"> </el-table-column> -->
      <el-table-column prop="avatar_url" label="头像" width="80">
        <template #default="item">
          <el-avatar
            :src="item.row.avatar_url ? item.row.avatar_url : avatarUrl"
          ></el-avatar>
        </template>
      </el-table-column>
      <el-table-column
        label="账号"
        sortable="custom"
        prop="account"
        width="120"
      ></el-table-column>
      <el-table-column
        label="姓名"
        sortable="custom"
        prop="name"
        width="120"
      ></el-table-column>
      <el-table-column prop="mobile_phone" label="手机号码" width="140">
      </el-table-column>
      <el-table-column label="角色">
        <template #default="item">
          {{ item.row.roles.map((x) => x.name).join("，") }}
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
        width="110"
        class-name="status"
      >
        <template #default="scope" >
          <el-popconfirm v-if="!scope.row.is_super"
            :title="
              scope.row.is_disabled ? '是否启用此用户？' : '是否禁用此用户？'
            "
            @confirm="onSwitch(scope.row)"
          >
            <template #reference>
              <el-button
                type="text"
                style="color: #f56c6c"
                v-if="scope.row.is_disabled"
                >禁用(点击修改)</el-button
              >
              <el-button type="success" link size="small" style="color: #67c23a" v-else
                >正常<span>(点击修改)</span></el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column prop="create_at" label="创建时间" width="180">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="180">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)"
            >修改</el-button
          >
          <el-popconfirm
            v-if="!scope.row.is_super"
            title="您确定要删除此用户？"
            @confirm="onDelete(scope.row)"
          >
            <template #reference>
              <el-button size="small" type="danger" link class="btn-del"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    >
    </el-pagination>
  </el-card>
  <edit-user :reload="loadData" :item="current"></edit-user>
</template>
<script>
import EditUser from "./edit_user.vue";

export default {
  components: { EditUser },
  data() {
    return {
      loading: true,
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      avatarUrl: require("@/assets/avatar.png"),
      tableData: {
        counts: 0,
      },
      current: null,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.current = null;
      this.loading = true;
      this.$http.post("/admin/v1/user", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 新增、修改
     */
    onEdit(item) {
      this.current = item ? item : {};
    },
    /**
     * 删除
     */
    onDelete(item) {
      this.$http.get('admin/v1/user/del?id='+item.id).then((res)=>{
        if(res.code==0){
          this.$message({
            type:'success',
            message:res.msg
          })
          this.loadData();
        }
      })
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    /**
     * 切换状态
     */
    onSwitch(user) {
      this.$http
        .get(
          "/admin/v1/user/switch?id=" +
            user.id +
            "&on=" +
            (user.is_disabled ? "1" : "0")
        )
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            user.is_disabled = !user.is_disabled;
          }
        });
    },
  },
};
</script>

<style scoped>
.status span {
  font-size: var(--el-font-size-extra-small);
  color: var(--text-label-color);
}
</style>
<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-09 11:01:59
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-09 14:32:37
-->

<template>
  <el-drawer
    v-model="drawer"
    :title="title"
    direction="rtl"
    size="800px"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <el-scrollbar>
      <el-form :model="form" ref="form" :rules="rules" label-width="140px">
        <el-form-item label="姓名" prop="name">
          <el-col :span="10">
            <el-input
              v-model="form.name"
              placeholder="请输入姓名"
              clearable
            ></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="登录账号" prop="account">
          <el-col :span="10">
            <el-input
              v-model="form.account"
              placeholder="请输入账号"
              :disabled="form.id > 0"
            ></el-input>
            <div class="help-block">支持中文、字母、数字、_</div>
          </el-col>
        </el-form-item>

        <el-form-item label="登录密码" prop="password" v-if="!form.id">
          <el-col :span="10">
            <el-input
              v-model="form.password"
              clearable
              placeholder="请输入登录密码"
            ></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="手机号码" prop="mobile_phone">
          <el-col :span="8">
            <el-input
              v-model="form.mobile_phone"
              placeholder=""
              clearable
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="停用">
          <el-switch v-model="form.is_disabled" />
        </el-form-item>

        <el-form-item label="角色">
          <el-select v-model="form.roleIds" multiple placeholder="选择角色" style="width: 340px">
            <el-option
              v-for="item in roles"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="onSubmit"
            :loading="loading"
            :disabled="loading"
            >确定</el-button
          >
          <el-button @click="cancelClick()" :disabled="loading">取消</el-button>
        </el-form-item>
      </el-form>
    </el-scrollbar>
  </el-drawer>
</template>
<script>
import role_api from "@/http/role_api";

export default {
  data() {
    return {
      //提交加载中
      loading: false,
      drawer: false,
      title: "编辑管理员",
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        account: [
          {
            required: true,
            message: "请输入登录账号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入登录密码",
            trigger: "blur",
          },
        ],
      },
      roles: [],
    };
  },
  props: ["item", "reload"],
  created() {
    role_api.get_roles().then((res) => {
      if (res.code == 0) {
        this.roles = res.data;
      }
    });
  },
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.drawer = true;
          this.form = Object.assign({},this.item);
        }
      },
    },
  },
  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/admin/v1/user/edit", this.form)
            .then((res) => {
              this.loading = false;
              if (res.code == 0) {
                this.drawer = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                if (this.reload) {
                  this.reload();
                }
              }
            })
            .cath(() => {
              this.loading = false;
            });
        }
      });
    },
    /**
     * 取消
     */
    cancelClick() {
      this.drawer = false;
      if (this.reload) {
        this.reload();
      }
    },
  },
};
</script>